<template>
  <div class="home">
   <router-link to="/about"> <div class="content">
  <h1 data-text="Choose Your Dinner">Choose Your Dinner</h1>
  <div class="content_star star-1"></div>
  <div class="content_star star-2"></div>
  <div class="content_star star-3"></div>
  <div class="content_star star-4"></div>
  <div class="content_star star-5"></div>
  <div class="content_arrow-wrapper">
    <img class="content_arrow" src="https://static.thenounproject.com/png/1563361-200.png">
    <p class="content_arrow-cute">cute!</p>
  </div>
</div></router-link>
</div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>

<style>
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  background-color: #fd99c8;
  border: 1px solid #000;
  font-family: "Montserrat", sans-serif;
  max-width: 500px;
}
.content h1 {
  text-transform: uppercase;
  font-size: 3rem;
  letter-spacing: 0.1em;
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  position: relative;
  display: inline-block;
}
.content h1:before {
  content: attr(data-text);
  position: absolute;
  top: 0.1em;
  left: -0.15em;
  z-index: -1;
  color: #fc5f84;
  animation: before-anim 1.2s infinite;
}
.content h1:after {
  content: attr(data-text);
  position: absolute;
  top: 0.2em;
  left: -0.3em;
  z-index: -2;
  color: lightgreen;
  animation: after-anim 1.2s infinite;
}
.content .content_star {
  position: absolute;
  top: 77%;
  left: 47%;
  width: 50px;
  height: 50px;
  background-image: url('https://cdn140.picsart.com/275056284004211.png?c256x256');
  -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  animation: rotate-star-anim 2s linear infinite;
}
.content .content_star.star-2 {
  top: 72%;
  left: 65%;
  width: 30px;
  height: 30px;
  animation: rotate-star-anim 1.2s linear infinite;
}
.content .content_star.star-3 {
  width: 38px;
  height: 38px;
  top: 20%;
  left: 85%;
  animation: rotate-star-anim 2s linear infinite;
}
.content .content_star.star-4 {
  width: 34px;
  height: 34px;
  top: 2%;
  left: 10%;
  animation: rotate-star-anim 1s linear infinite;
}
.content .content_star.star-5 {
  width: 46px;
  height: 46px;
  top: -8%;
  left: 45%;
  animation: rotate-star-anim 1.5s linear infinite;
}
.content .content_arrow-wrapper {
  position: absolute;
  right: -20%;
  top: 75%;
  width: 150px;
  height: 150px;
}
.content .content_arrow-wrapper .content_arrow {
  width: 80%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}
.content .content_arrow-wrapper .content_arrow-cute {
  position: absolute;
  font-size: 2.4em;
  margin: 0;
  bottom: 0;
  left: 50%;
  font-family: "Damion", cursive;
  animation: rotate-cute-anim 0.8s infinite forwards;
  transform: rotate(30deg);
}

@-webkit-keyframes after-anim {
  25% {
    color: #fc5f84;
  }
  50% {
    color: lightgreen;
  }
  76% {
    color: #fc5f84;
  }
  100% {
    color: lightgreen;
  }
}
@-moz-keyframes after-anim {
  25% {
    color: #fc5f84;
  }
  50% {
    color: lightgreen;
  }
  76% {
    color: #fc5f84;
  }
  100% {
    color: lightgreen;
  }
}
@-ms-keyframes after-anim {
  25% {
    color: #fc5f84;
  }
  50% {
    color: lightgreen;
  }
  76% {
    color: #fc5f84;
  }
  100% {
    color: lightgreen;
  }
}
@-o-keyframes after-anim {
  25% {
    color: #fc5f84;
  }
  50% {
    color: lightgreen;
  }
  76% {
    color: #fc5f84;
  }
  100% {
    color: lightgreen;
  }
}
@keyframes after-anim {
  25% {
    color: #fc5f84;
  }
  50% {
    color: lightgreen;
  }
  76% {
    color: #fc5f84;
  }
  100% {
    color: lightgreen;
  }
}
@-webkit-keyframes before-anim {
  25% {
    color: lightgreen;
  }
  50% {
    color: #fc5f84;
  }
  76% {
    color: lightgreen;
  }
  100% {
    color: #fc5f84;
  }
}
@-moz-keyframes before-anim {
  25% {
    color: lightgreen;
  }
  50% {
    color: #fc5f84;
  }
  76% {
    color: lightgreen;
  }
  100% {
    color: #fc5f84;
  }
}
@-ms-keyframes before-anim {
  25% {
    color: lightgreen;
  }
  50% {
    color: #fc5f84;
  }
  76% {
    color: lightgreen;
  }
  100% {
    color: #fc5f84;
  }
}
@-o-keyframes before-anim {
  25% {
    color: lightgreen;
  }
  50% {
    color: #fc5f84;
  }
  76% {
    color: lightgreen;
  }
  100% {
    color: #fc5f84;
  }
}
@keyframes before-anim {
  25% {
    color: lightgreen;
  }
  50% {
    color: #fc5f84;
  }
  76% {
    color: lightgreen;
  }
  100% {
    color: #fc5f84;
  }
}
@-webkit-keyframes rotate-star-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotate-star-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-ms-keyframes rotate-star-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes rotate-star-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate-star-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate-cute-anim {
  from {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(30deg);
  }
}
@-moz-keyframes rotate-cute-anim {
  from {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(30deg);
  }
}
@-ms-keyframes rotate-cute-anim {
  from {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(30deg);
  }
}
@-o-keyframes rotate-cute-anim {
  from {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(30deg);
  }
}
@keyframes rotate-cute-anim {
  from {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(30deg);
  }
}</style>
